@use '../abstract' as *;

.container {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;

    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: rgba(255, 255, 255, .8);
}

.modal {
    position: relative;

    padding: 3rem 5rem;
    padding-top: 5rem;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 3rem;

    background-color: var(--color-white);
    border-radius: 2rem;
    border: 1px solid var(--color-primary);
    box-shadow: var(--shadow-primary);


    transform-origin: center;
    animation: modal-open .6s;
    animation-fill-mode: forwards;
}

.close-container {
    position: absolute;
    top: 1.3rem;
    right: 1.3rem;
    width: 2rem;
    height: 2rem;

    border-radius: 50%;
    border: 1px solid var(--color-error-light-2);

    cursor: pointer;
}

.close-container:hover {
    border: 1px solid var(--color-error-light);
    box-shadow: var(--shadow-error);
}

.close-container:active {
    transform: translateY(1px);
}

.close::before, .close::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: .9rem;
    left: .5rem;

    width: 1rem;
    height: 2px;

    background-color: #FFC2D1;
}

.close::before {
    transform: rotate(135deg);
}

.close::after {
    transform: rotate(-135deg);
}

.close-container:hover .close::before,
.close-container:hover .close::after {
    background-color: var(--color-error-light);
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2.5rem;

    text-decoration: none;
}

@keyframes modal-open {
    0% { transform: scale(0); }
    100% { transform: scale(1); }
}