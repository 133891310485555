@use '../abstract' as *;

.container {
    padding-top: 3rem;

    display: flex;
    justify-content: center;
    align-items: center;
}

.button {
    padding: 1rem 5rem;

    background-color: var(--color-confirm-light-2);
    box-shadow: var(--shadow-confirm);
    border-radius: 100px;
    border: 1px solid var(--color-confirm);

    cursor: pointer;

    transition: border 150ms cubic-bezier(0.4, 0, 0.2, 1);
}

.button:active {
    transform: translateY(0.1rem);
    box-shadow: inset 0 .5rem 1rem rgba($color-black, 0.2);
}

.button:disabled {
    transform: translateY(0);
    background-color: var(--color-error-light-2);
    box-shadow: var(--shadow-error);
    border: 1px solid var(--color-error);
}