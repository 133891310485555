/*
    $breakpoint argument choices:
    - phone
    - tab-port
    - tab-land
    - big-desktop
*/
@mixin respond($breakpoint) {
	@if $breakpoint == phone {
		@media only screen and (max-width: 37.5em) { @content; }	// 600px
	}

	@if $breakpoint == tab-port {
		@media only screen and (max-width: 56.25em) { @content; }	// 900px
	}

	@if $breakpoint == tab-land {
		@media only screen and (max-width: 75em) { @content; }		// 1200px
	}

	@if $breakpoint == big-desktop {
		@media only screen and (min-width: 112.5em) { @content; }	// 1800px
	}
}

@mixin safarifix {
    @supports (-webkit-appearance:none) and (stroke-color:transparent) { @content; }
}

@mixin clearfix {
	&::after {
		content: '';
		display: table;
		clear: both;
	}
}

@mixin absCenter {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

@mixin fullScreen {
    margin: 0;
    height: 100vh;
    width: 100vw;
}