@use '../abstract' as *;

.container {
    padding: 5rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2.5rem;
}