@use 'abstract' as *;

@import url('https://fonts.googleapis.com/css?family=Lato&display=swap');

:root {
    --color-primary: #185EE0;
    --color-primary-light: #E6EEF9;

    --color-confirm: #28B485;
    --color-confirm-light: #7ED56F;
    --color-confirm-light-2: rgba(126, 213, 111, 0.3);

    --color-error: #EB2F64;
    --color-error-light: #FF3366;
    --color-error-light-2: rgba(255, 51, 102, 0.3);

    --color-grey-light: #ccc;
    --color-grey-dark: #999;
    --color-grey-dark-2: #666;

    --color-white: #FFF;

    --font-size: 1.6rem;

    --shadow-primary: 0 0 1px 0 rgba(24, 94, 224, 0.3), 0 6px 12px 0 rgba(24, 94, 224, 0.15);
    --shadow-primary-light: 0 0 1px 0 rgba(24, 94, 224, 0.15), 0 6px 12px 0 rgba(24, 94, 224, 0.15);
    --shadow-confirm: 0 0 1px 0 rgba(126, 213, 111, 0.5), 0 6px 12px 0 rgba(126, 213, 111, 0.3);
    --shadow-error: 0 0 1px 0 rgba(235, 47, 100, 0.15), 0 6px 12px 0 rgba(235, 47, 100, 0.15);
}

*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}

html {
    font-size: 62.5%;

    @include respond(tab-land) { font-size: 56.25%; }
    @include respond(tab-port) { font-size: 50%; }
    @include respond(big-desktop) { font-size: 75%; }
}

body {
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    line-height: 1.7;
    color: var(--color-grey-dark-2);
}