@use '../abstract' as *;

.tabs {
    display: flex;
    position: relative;
    background-color: var(--color-white);
    box-shadow: var(--shadow-light);
    padding: 0.75rem;
    border: 1px solid var(--color-grey-light);
    border-radius: 10rem;

    @include respond(phone) {
        transform: scale(0.6);
	}
}

.tabs * {
    z-index: 2;
}

.container input[type="radio"] {
    display: none;
}

.tab {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 3rem;
    width: 10rem;
    color: var(--color-grey-dark);
    font-weight: 500;
    border-radius: 10rem;
    cursor: pointer;
    transition: color 0.15s ease-in;
}

.container input[type="radio"]:checked + label {
    color: var(--color-primary);
}

.container input[id="radio-1"]:checked ~ .glider {
    transform: translateX(0);
}

.container input[id="radio-2"]:checked ~ .glider {
    transform: translateX(100%);
}

.container input[id="radio-3"]:checked ~ .glider {
    transform: translateX(200%);
}

.glider {
    position: absolute;
    display: flex;
    height: 3rem;
    width: 10rem;
    background-color: var(--color-primary-light);
    z-index: 1;
    border-radius: 10rem;
    transition: 0.25s ease-out;
}