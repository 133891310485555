@use '../abstract' as *;

.container {
    padding: 5rem 0;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2.5rem;
}

.key-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.key-text span {
    padding: 2px 4px;
    border: 2px solid var(--color-primary-light);
    font-size: 1.6rem;
    font-weight: 600;
}

.info {

}

.info a {
    text-decoration: none;
    color: var(--color-grey-dark);
    font-weight: 500;
}

.confirm-text {
    font-size: 1.2rem;
    font-weight: 500;
    margin-bottom: -2rem;
}