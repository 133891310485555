.center-text {
    text-align: center;
}

.margin-bottom {
    &--small {
        margin-bottom: 1.5rem;
    }

    &--medium {
        margin-bottom: 4rem;

        @media (max-width: 56.25em) {
            margin-bottom: 3rem;
        }
    }

    &--big {
        margin-bottom: 8rem;

        @media (max-width: 56.25em) {
            margin-bottom: 5rem;
        }
    }
}

.margin-top {
    &--small {
        margin-top: 1.5rem;
    }

    &--medium {
        margin-top: 4rem;

        @media (max-width: 56.25em) {
            margin-top: 3rem;
        }
    }

    &--big {
        margin-top: 8rem;

        @media (max-width: 56.25em) {
            margin-top: 5rem;
        }
    }

    &--huge {
        margin-top: 10rem;
    }
}