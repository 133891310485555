@use '../abstract' as *;

.container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;

    padding: 0 1rem;
}

.wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 2rem;

    background-color: var(--color-white);
    border-radius: 50%;
    border: 2px solid var(--color-primary);
    box-shadow: var(--shadow-primary);

    user-select: none;
    cursor: pointer;

    transition: border .7s;
}

.wrapper input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.checkmark {
    position: relative;
    top: 0;
    left: 0;
    height: .8em;
    width: .8em;
    transition: .3s;
    transform: scale(0);
    border-radius: 50%;
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
    left: 0.25em;
    top: 0.15em;
    width: 0.15em;
    height: 0.35em;
    border: solid var(--color-white);
    border-width: 0 0.15em 0.15em 0;
    transform: rotate(45deg);
}

.checked-wrapper {
    background-color: var(--color-confirm);
    border: 2px solid var(--color-confirm);
    box-shadow: var(--shadow-confirm);
}

.wrapper input:checked ~ .checkmark {
    transform: scale(1);
}

.wrapper input:checked ~ .checkmark:after,
.wrapper input:checked ~ .celebrate {
    display: block;
}

.wrapper .celebrate {
    position: absolute;
    transform-origin: center;
    animation: kfr-celebrate .4s;
    animation-fill-mode: forwards;
    display: none;
    stroke: var(--color-confirm);
}

.text {
    padding-left: .8rem;

    color: var(--color-grey-dark);
}

@keyframes kfr-celebrate {
    0% {
        transform: scale(0);
    }

    50% {
        opacity: 1;
    }

    100% {
        transform: scale(1);
        opacity: 0;
        display: none;
    }
}