@use '../abstract' as *;

.container {
    position: relative;
    width: 25rem;
}

.select {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 1.5rem;

    background-color: var(--color-white);
    box-shadow: var(--shadow-primary-light);
    border-radius: 10rem;
    border: solid 1px var(--color-grey-light);

    cursor: pointer;
}

.text {
    font-weight: 600;
    color: var(--color-grey-light);
}

.selected {
    color: var(--color-grey-dark-2);
}

.arrow-container {
    display: flex;
    height: 21px;
    width: 21px;
    color: var(--color-white);
    font-size: 14px;
    border-radius: 50%;
    background: var(--color-primary);
    align-items: center;
    justify-content: center;
    transition: 0.3s;
}

.arrow:before {
    content: "";
    position: absolute;
    display: block;
    width: 8px;
    border-bottom: 0.15em solid var(--color-white);
    transform: translateX(-2px) rotate(135deg);
}

.arrow:after {
    content: "";
    position: absolute;
    display: block;
    width: 8px;
    border-bottom: 0.15em solid var(--color-white);
    transform: translateX(-6px) rotate(45deg);
}

.list {
    position: relative;
    padding: 1rem;
    margin-top: -1px;

    border-radius: 2.5rem;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    background-color: var(--color-white);
    box-shadow: var(--shadow-primary);
    border: solid 1px var(--color-grey-light);
    display: none;
}

.open {
    border-radius: 2.5rem;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.open .arrow-container {
    transform: rotate(-180deg);
}

.open ~ .list {
    display: block;
    transition: all .3s cubic-bezier(0.075, 0.82, 0.165, 1);
}