@use '../abstract' as *;

.item {
    height: 3.5rem;
    padding: 0 1rem;
    display: flex;
    align-items: center;

    border-radius: 5rem;

    list-style: none;
    cursor: pointer;

    transition: 0.3s;
}

.item:hover {
    background-color: var(--color-primary-light);
}

.text {
    color: var(--color-grey-dark);
}