@use '../abstract' as *;

.item {
    height: 3.5rem;
    padding: 0 1rem;
    display: flex;
    align-items: center;

    border-radius: 5rem;

    list-style: none;
    cursor: pointer;

    transition: 0.3s;
}

.item:hover {
    background-color: var(--color-primary-light);
}

.text {
    color: var(--color-grey-dark);
}

.checkbox {
    height: 1.6rem;
    width: 1.6rem;
    margin-right: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 50%;
    border: 1.5px solid var(--color-grey-light);

    transition: all 0.3s ease-in-out;
}

.checkmark {
    position: relative;
    top: 0;
    left: 0;
    height: .8em;
    width: .8em;
    transition: .3s;
    transform: scale(0);
    border-radius: 50%;
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
    left: 0.20em;
    top: 0;
    width: 0.30em;
    height: 0.55em;
    border: solid var(--color-white);
    border-width: 0 0.15em 0.15em 0;
    transform: rotate(45deg);
}

.checked .checkmark {
    transform: scale(1);
}

.checked .checkmark:after{
    display: block;
}

.checked .checkbox {
    background-color: var(--color-primary);
    border-color: var(--color-primary);
}

.checked .check-icon {
    transform: scale(1);
}