@use '../abstract' as *;

.container {
    position: relative;
    width: 100%;
    max-width: 300px;
}

.input {
    width: 90%;
    margin: 0 auto;
    box-shadow: var(--shadow-primary-light);
    border: solid 1px var(--color-grey-light);
    border-radius: 10rem;
    background: none;
    padding: 1rem;
    font-size: 1rem;
    color: var(--color-grey-dark);
    transition: border 150ms cubic-bezier(0.4, 0, 0.2, 1);
}

.label {
    position: absolute;
    left: 1.5rem;
    color: var(--color-grey-light);
    pointer-events: none;
    transform: translateY(1rem);
    transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
}


.input:focus, .filled-input {
    outline: none;
    color: var(--color-error-light);
    box-shadow: var(--shadow-error);
    border: 1px solid var(--color-error);
}

.input:valid {
    outline: none;
    color: var(--color-grey-dark);
    box-shadow: var(--shadow-primary-light);
    border: 1px solid var(--color-primary);
}

.input:focus ~ label, .input:valid ~ label, .filled-input ~ label {
    transform: translateY(-50%) scale(0.8);
    background-color: var(--color-white);
    padding: 0 .2em;
    color: var(--color-primary);
}