@use '../abstract' as *;

.container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo {
    width: 200px;
}